<template>
    <div v-if="!this.lista.length">
        <div class="flex my-4 justify-content-center">
            <h5>Nenhum item encontrado</h5>
        </div>
    </div>
    <div v-for="subArray of Object.entries(this.groupObjectsByDate(this.lista)).reverse()" :key="subArray">
        <div class="flex align-items-center gap-2 my-2">
            <span class="pi pi-calendar" />
            <p class="mb-0">{{ subArray[0].split('-').reverse().join('-').replaceAll('-', '/') }}</p>
            <Divider />
        </div>
        <div class="flex" style="height: 100%; position: relative; min-height: 100px" v-for="(item, index) of subArray[1]" :key="item">
            <div class="flex pl-4" style="width: 20%">
                <div class="user-avatar">
                    <img v-if="item.userCreate.urlFotoPerfil" class="user-avatar" :src="item.userCreate.urlFotoPerfil" /><i
                        v-else
                        class="pi pi-user"
                    />
                </div>
                <div class="flex flex-column ml-3">
                    <h5 class="mb-0">{{ item.userCreate.name }}</h5>
                    <p style="font-size: 12px">
                        <span v-if="item.comentarioInterno" class="comentario-interno">Interno</span><span v-if="item.comentarioInterno"> - </span
                        >{{ item.dataAtualizacao.split('T')[1].substr(0, 5) }}
                    </p>
                </div>
            </div>
            <div style="width: 65%">
                <p v-if="!item.edit" v-html="item.descricaoAtualizacao" />
                <Editor ref="editor" v-else :modelValue="item.descricaoAtualizacao" required="true" @textChange="(e) => textChange(e, item)">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                                <option value="1">Heading 1</option>
                                <option value="2">Subheading</option>
                                <option value="3">Normal</option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                                <option label="Sans Serif" value="Arial, sans-serif"></option>
                                <option label="Serif" value="'Georgia', serif"></option>
                                <option label="Monospace" value="'Courier New', monospace"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                            <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                            <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                                <option value=""></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="flex flex-column justify-content-end" style="width: 15%">
                <div class="flex justify-content-end mb-4">
                    <Button
                        class="p-button-text p-button-secondary"
                        @click="editar(item)"
                        v-if="!item.edit"
                        :disabled="this.planoAcao.status === 'CONCLUIDO' && item.userIdCreate !== this.usuario.id"
                    >
                        <i class="pi pi-pencil mr-2" />Editar
                    </Button>
                    <Button
                        v-else
                        class="p-button-text p-button-secondary"
                        @click="this.edit({ id: item.id, descricaoAtualizacao: item.descricaoAtualizacaoAux }, item)"
                        :disabled="this.planoAcao.status === 'CONCLUIDO' && item.userIdCreate !== this.usuario.id"
                    >
                        <i class="pi pi-check mr-2" />Confirmar
                    </Button>
                    <Button
                        class="p-button-text p-button-secondary"
                        @click="this.delete(item.id)"
                        v-if="!item.edit"
                        :disabled="this.planoAcao.status === 'CONCLUIDO' && item.userIdCreate !== this.usuario.id"
                    >
                        <i class="pi pi-trash mr-2" />Remover
                    </Button>
                    <Button class="p-button-text p-button-secondary" @click="cancelar()" v-else> <i class="pi pi-times mr-2" />Cancelar </Button>
                </div>

                <p class="link-anexos text-right" @click="() => (abrirModalAnexos[subArray[0]][index + 1] = true)">
                    <i class="pi pi-paperclip mr-2" />
                    {{
                        this.anexos.some((element) => element.planoAcaoAcompanhamentoId === item.id)
                            ? `(${this.anexos.filter((element) => element.planoAcaoAcompanhamentoId === item.id).length}) Visualizar`
                            : 'Incluir'
                    }}
                    anexo
                </p>
            </div>
            <Dialog v-model:visible="abrirModalAnexos[subArray[0]][index + 1]" modal :style="{ width: '667px' }">
                <template #header>
                    <h4 class="mb-1">Anexos</h4>
                </template>
                <DataTable
                    dataKey="id"
                    :value="anexos.filter((element) => element.planoAcaoAcompanhamentoId === item.id)"
                    :row-hover="true"
                    responsiveLayout="scroll"
                    breakpoint="640px"
                    :rows="10"
                    style="cursor: pointer"
                    selectionMode="single"
                >
                    <template #empty> Nenhum registro encontrado. </template>

                    <template #loading> Carregando registros. Aguarde ... </template>
                    <Column field="nomeArquivo" header="Nome">
                        <template #body="slotProps">
                            <label :class="`${slotProps.data?.nomeArquivo}`">
                                <a :href="slotProps.data?.url" target="_blank">{{ slotProps.data?.nomeArquivo }}</a>
                            </label>
                        </template>
                    </Column>
                    <Column field="prioridadeAcao" header="Data de atualização">
                        <template #body="slotProps">
                            <label>
                                {{ slotProps.data ? new Date(slotProps.data.updatedAt).toLocaleString().replace(',', ' as ') : '' }}
                            </label>
                        </template>
                    </Column>
                    <Column bodyClass="text-right" headerStyle="width: 10rem;">
                        <template #body="slotProps">
                            <div class="flex flex-row gap-2">
                                <Button @click="(e) => downloadAnexo(e, slotProps.data)" class="p-button-text p-button-secondary"
                                    ><i class="pi pi-check mr-2" />Baixar</Button
                                >
                                <Button
                                    class="p-button-text p-button-secondary"
                                    @click="deleteFile(slotProps.data.id)"
                                    :disabled="this.planoAcao?.status === 'CONCLUIDO'"
                                    ><i class="pi pi-trash mr-2" />Excluir</Button
                                >
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <template #footer>
                    <Button label="Fechar" @click="abrirModalAnexos[subArray[0]][index + 1] = false" class="p-button-outlined mr-4 b-rounded" />
                    <Button label="Adicionar anexo" @click="openInputFile" class="b-rounded" />
                    <input hidden type="file" ref="inputFile" @change="handleInputFile(item.id)" multiple />
                </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import PlanoAcaoAcompanhamentoService from '../../../services/planoAcaoAcompanhamentoService';
import PlanoAcaoAnexosService from '../../../services/planoAcaoAnexosService';
import moment from 'moment';
import PlanoAcaoService from '../../../services/planoAcaoService';
import axios from 'axios';
import { getProfile } from '../../../services/auth';

export default {
    data() {
        return {
            anexos: [],
            abrirModalAnexos: {},
            planoAcao: {},
            selectedItem: false,
            usuario: {}
        };
    },
    name: 'ListaAcompanhamento',
    emits: ['getData'],
    props: {
        lista: {
            type: Array
        }
    },

    async mounted() {
        this.$service = new PlanoAcaoAcompanhamentoService();
        this.$anexosService = new PlanoAcaoAnexosService();
        const { data } = await new PlanoAcaoService().get('', '', this.$route.params.id);

        this.planoAcao = data;

        this.usuario = await getProfile();

        await this.getInnerData();
    },

    methods: {
        async getInnerData() {
            const response = await this.$anexosService.getByPlan(this.$route.params.id);

            this.anexos = response.data;
        },
        groupObjectsByDate(arr) {
            let result = {};

            arr.forEach((obj) => {
                const dateStr = obj.dataAtualizacao.split('T')[0];

                const updatedAt = moment(obj.updatedAt).toDate();

                if (result[dateStr]) {
                    result[dateStr].push({ ...obj, updatedAt });
                } else {
                    result[dateStr] = [{ ...obj, updatedAt }];
                }
            });

            Object.keys(result).forEach((data) => {
                result[data].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            });

            Object.entries(result).forEach((subArray) => {
                if (!this.abrirModalAnexos[subArray[0]]) {
                    return (this.abrirModalAnexos[subArray[0]] = [subArray[1].map(() => false)]);
                }
            });

            return result;
        },
        async delete(id) {
            try {
                await this.$service.delete(id).then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Removido com sucesso',
                        life: 3000
                    });
                    this.$emit('getData');
                });
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao deletar',
                    life: 3000
                });
            }
        },
        async edit(payload, item) {
            try {
                if (item.descricaoAtualizacaoAux && item.descricaoAtualizacaoAux !== item.descricaoAtualizacao) {
                    await this.$service.patch(payload.id, payload).then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Atualizado com sucesso',
                            life: 3000
                        });
                        this.$emit('getData');
                    });
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não é possível salvar uma mensagem vazia ou sem alteração',
                        life: 3000
                    });
                }
                this.selectedItem = false;
            } catch (error) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao atualizar',
                    life: 3000
                });
            }
        },
        cancelar() {
            this.selectedItem = false;
        },
        editar(item) {
            this.selectedItem = item;
            this.selectedItem.edit = true;
            this.selectedItem.descricaoAtualizacaoAux = `${item.descricaoAtualizacao}`;
        },
        textChange({ htmlValue }, item) {
            item.descricaoAtualizacaoAux = htmlValue;
        },
        openInputFile() {
            const inputFile = this.$refs.inputFile;
            inputFile.click();
        },
        async handleInputFile(id) {
            const inputFile = this.$refs.inputFile;

            for (let i = 0; i < inputFile.files.length; i++) {
                const formData = new FormData();

                formData.append('file', inputFile.files[i]);
                formData.append('planoAcaoId', this.$route.params.id);
                formData.append('planoAcaoAcompanhamentoId', id);
                formData.append('tipo', inputFile.files[i].type);

                await this.$anexosService.post(formData);
            }

            await this.getInnerData();

            return this.$toast.add({
                severity: 'success',
                summary: 'Anexo(s) cadastrado(s) com sucesso',
                life: 3000
            });
        },
        async deleteFile(id) {
            await this.$anexosService.delete(id).then(async () => {
                await this.getInnerData();

                return this.$toast.add({
                    severity: 'success',
                    summary: 'Anexo excluido com sucesso',
                    life: 3000
                });
            });
        },
        downloadAnexo($event, record) {
            axios({
                url: record.url,
                method: 'GET',
                responseType: 'blob'
            }).then((res) => {
                const file = window.URL.createObjectURL(new Blob([res.data]));

                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', record.nomeArquivo);
                document.body.appendChild(docUrl);
                docUrl.click();
            });
        }
    }
};
</script>

<style scoped>
.user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #5e5e5e;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.comentario-interno {
    color: #ef2595;
}

.link-anexos {
    color: #4c9ddf;
    cursor: pointer;
}
</style>
