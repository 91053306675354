<template>
    <div class="flex flex-column align-items-center" :class="`${loading && 'pointer-events-none'}`">
        <div v-if="this.lista.length === 0 && !firstAdd" class="flex flex-column align-items-center empty-list">
            <ListaVaziaIcone class="mb-3" />
            <p class="mb-5" style="font-weight: 500">Ainda não há comentários</p>
            <Button
                label="Fazer primeiro comentário"
                icon="pi pi-plus"
                style="height: 32px"
                @click="this.firstAdd = true"
                :disabled="this.planoAcao?.status === 'CONCLUIDO'"
            />
        </div>
        <div v-if="this.lista.length > 0" style="width: 100%">
            <div class="flex justify-content-end">
                <Button
                    class="p-button-text"
                    @click="
                        () => {
                            if (this.filtered) {
                                return this.$toast.add({
                                    severity: 'error',
                                    summary: 'Filtros aplicados, remova-os para fechar a aba',
                                    life: 3000
                                });
                            } else {
                                this.showFilters = !this.showFilters;
                            }
                        }
                    "
                    ><i class="pi pi-filter mr-2" /> {{ this.showFilters ? '-' : '+' }} Filtros</Button
                >
            </div>
            <div class="grid" v-if="showFilters">
                <div class="col-5">
                    <label for="search">Pesquisar</label>
                    <span id="search" class="p-input-icon-right input-text" style="width: 100%">
                        <InputText v-model="filters.search" style="width: 100%" />
                        <i class="pi pi-search pr-2" />
                    </span>
                </div>
                <div class="col">
                    <label for="date">Periodo de data</label>
                    <Calendar
                        @date-select="this.filtered = false"
                        selectionMode="range"
                        class="input-date"
                        id="date"
                        v-model="filters.date"
                        showIcon
                        style="width: 100%"
                    />
                </div>
                <div class="col flex justify-content-end pb-0">
                    <div class="flex align-items-center pt-2 pb-2 gap-1">
                        <InputSwitch v-model="filters.comAnexo" class="mr-2" />
                        <p class="m-0 pb-1">Com anexo</p>
                    </div>
                </div>
                <div class="col-2 flex justify-content-center pb-0">
                    <div class="flex align-items-center pt-2 pb-2 gap-1">
                        <InputSwitch v-model="filters.comentarioInterno" class="mr-2" />
                        <p class="m-0 pb-1">Comentário interno</p>
                    </div>
                </div>
                <div class="col flex justify-content-end align-items-center pb-0">
                    <Button :disabled="!this.filtered" class="p-button-text" style="width: 134px; height: 36px" @click="this.removeFilters()"
                        ><i class="pi pi-filter-slash mr-2" />Remover Filtros</Button
                    >
                </div>
                <div class="col flex justify-content-end align-items-center pb-0">
                    <Button
                        :disabled="
                            this.filtered ||
                            (this.filters.search === '' && !this.filters.date && !this.filters.comAnexo && !this.filters.comentarioInterno)
                        "
                        class="p-button-text p-button-outlined"
                        style="height: 36px"
                        @click="this.filtered = true"
                        ><i class="pi pi-filter mr-2" />Filtrar</Button
                    >
                </div>
            </div>
            <ListaAcompanhamento @getData="this.$emit('getData')" :lista="this.filtered ? this.filterList() : this.lista" />
        </div>
        <div v-if="this.lista.length > 0 || firstAdd" style="width: 100%">
            <label for="add" class="input-label">Novo Comentário</label>
            <div style="position: relative">
                <Editor id="add" v-model="descricao" class="mt-1" style="height: 66px">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <select class="ql-header" v-tooltip.bottom="'Heading'" :title="'Heading'">
                                <option value="1">Heading 1</option>
                                <option value="2">Subheading</option>
                                <option value="3">Normal</option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-font" v-tooltip.bottom="'Font'" :title="'Font'">
                                <option label="Sans Serif" value="Arial, sans-serif"></option>
                                <option label="Serif" value="'Georgia', serif"></option>
                                <option label="Monospace" value="'Courier New', monospace"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                            <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                            <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Text Color'" class="ql-color"></button>
                            <button v-tooltip.bottom="'Background Color'" class="ql-background"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
                            <button v-tooltip.bottom="'Numbered List'" class="ql-list" value="bullet"></button>
                            <select class="ql-align" v-tooltip.bottom="'Text Align'" :title="'Text Align'">
                                <option value=""></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                            <button v-tooltip.bottom="'Code Block'" class="ql-code-block"></button>
                        </span>
                        <span class="ql-formats">
                            <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                        </span>
                    </template>
                </Editor>
                <div class="flex align-items-center pt-2 pb-2 gap-1 editor-switch">
                    <InputSwitch v-model="comentarioInterno" class="mr-2" />
                    <p class="m-0 pb-1">Comentário interno</p>
                </div>
                <Button
                    label="Enviar comentário"
                    class="editor-button"
                    @click="this.save()"
                    :disabled="this.planoAcao?.status === 'CONCLUIDO'"
                    :loading="loading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ListaVaziaIcone from './lista-vazia.vue';
import ListaAcompanhamento from './lista-acompanhamento.vue';
import PlanoAcaoAcompanhamentoService from '../../../services/planoAcaoAcompanhamentoService';
import PlanoAcaoService from '../../../services/planoAcaoService';
import PlanoAcaoAnexosService from '../../../services/planoAcaoAnexosService';

export default {
    data() {
        return {
            anexos: [],
            planoAcao: {},
            firstAdd: false,
            loading: false,
            descricao: '',
            comentarioInterno: false,
            showFilters: false,
            filtered: false,
            filters: {
                comAnexo: false,
                search: '',
                date: null,
                comentarioInterno: false
            }
        };
    },
    name: 'Comentarios',
    props: {
        lista: {
            type: Array
        }
    },

    async mounted() {
        this.$service = new PlanoAcaoAcompanhamentoService();
        const response = await new PlanoAcaoService().get('', '', this.$route.params.id);
        this.planoAcao = response.data;

        const { data } = await new PlanoAcaoAnexosService().getByPlan(this.$route.params.id);

        this.anexos = data;
    },

    components: { ListaVaziaIcone, ListaAcompanhamento },

    methods: {
        async save() {
            if (!this.descricao) {
                return this.$toast.add({
                    severity: 'error',
                    summary: 'Campo de descrição obrigatório',
                    life: 3000
                });
            }
            this.loading = true;

            const now = new Date();
            now.setHours(now.getHours() - 3);

            const dataToSave = {
                planoAcaoId: this.$route.params.id,
                dataAtualizacao: now.toISOString(),
                tipoAtualizacao: 'COMENTARIO',
                descricaoAtualizacao: this.descricao,
                comentarioInterno: this.comentarioInterno
            };

            await this.$service.post(dataToSave).then(() => {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Comentário cadastrado com sucesso',
                    life: 3000
                });
                this.descricao = '';
                this.comentarioInterno = false;
            });

            this.loading = false;
            this.$emit('getData');
        },
        removeFilters() {
            this.$emit('getData');
            this.filters = {
                comAnexo: false,
                search: '',
                date: null,
                comentarioInterno: false
            };
            this.filtered = false;
        },
        filterList() {
            let aux = [...this.lista];

            if (this.filters.search) {
                aux = this.filterListBySearch(this.filters.search);
            }

            if (this.filters.date) {
                aux = this.filterListByDate(this.filters.date);
            }

            if (this.filters.comAnexo) {
                aux = this.filterListByComAnexo();
            }

            if (this.filters.comentarioInterno) {
                aux = this.filterListByComentarioInterno();
            }

            return aux;
        },
        filterListBySearch(search) {
            return this.lista.filter((element) => element.descricaoAtualizacao.toLowerCase().includes(search.toLowerCase()));
        },
        filterListByDate(dateRange) {
            return this.lista.filter((element) => {
                const date = element.dataAtualizacao.split('T')[0];
                return date >= new Date(dateRange[0]).toISOString().split('T')[0] && date <= new Date(dateRange[1]).toISOString().split('T')[0];
            });
        },
        filterListByComAnexo() {
            return this.lista.filter((element) => this.anexos.some((el) => el.planoAcaoAcompanhamentoId === element.id));
        },
        filterListByComentarioInterno() {
            return this.lista.filter((element) => element.comentarioInterno);
        }
    }
};
</script>

<style scoped>
.empty-list {
    width: 202px;
}

.editor-switch {
    height: 32px;
    position: absolute;
    right: 156px;
    bottom: -24px;
}

.editor-button {
    height: 32px;
    position: absolute;
    right: 16px;
    bottom: -24px;
}

.input-text,
.input-date {
    margin-top: 6px;
}
</style>
